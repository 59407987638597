export default (): PagesState => ({
  nodes: [],
  breadcrumbs: [],
  pageInfo: {
    endCursor: "",
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: ""
  },
  page: {
    id: "",
    slug: "",
    title: "",
    template: {
      templateName: ""
    },
    frontendUrl: "",
    content: "",
    blocks: [],
    excerpt: "",
    location: {
      mapPinsLatitude: "",
      mapPinsLongitude: "",
      mapPinsStars: "",
      mapPinsTextLine1: "",
      mapPinsTextLine2: "",
      mapPinsTextLine3: "",
      mapPinsImageIDs: ""
    },
    uri: "",
    status: "",
    seo: {
      metaRobotsNoindex: "index",
      metaRobotsNofollow: "follow",
      canonical: "",
      metaDesc: "",
      opengraphTitle: "",
      opengraphType: "",
      opengraphUrl: "",
      opengraphDescription: "",
      opengraphSiteName: "",
      opengraphImage: {},
      twitterTitle: "",
      twitterDescription: "",
      twitterImage: {},
      breadcrumbs: []
    },
    featuredImage: {},
    pageFolders: {},
    locationForm: {},
    internalReviews: {},
    customPhoneNumber: ""
  },
  inventory: undefined, // undefined = no Location Form, null = Location Form loading, true/false = inventory on/off
  inventoryDesktop: undefined, // undefined = no Location Form, null = Location Form Extension loading, true/false = inventory desktop on/off
  windowWidth: undefined
})
