import { _SET_MAP_LOCATIONS } from "./mutations"

export const SET_MAP_LOCATIONS = "setMapLocations"

export default {
  [SET_MAP_LOCATIONS]({ commit }, locationData: PageLocation) {
    const latitudeList: number[] = locationData.mapPinsLatitude.split(/,/g).map(parseFloat)
    const longitudeList: number[] = locationData.mapPinsLongitude.split(/,/g).map(parseFloat)
    const textLine1List: string[] = locationData.mapPinsTextLine1.split(/,/g).map(str => str.trim())
    const textLine2List: string[] = locationData.mapPinsTextLine2.split(/,/g).map(str => str.trim())
    const textLine3List: string[] = locationData.mapPinsTextLine3.split(/,/g).map(str => str.trim())
    const starsList: string[] = locationData.mapPinsStars.split(/,/g).map(str => str.trim())
    const locations: MapLocation[] = latitudeList.map((value, index) => {
      return {
        id: index,
        lat: value,
        lng: longitudeList[index],
        textLine1: textLine1List[index],
        textLine2: textLine2List[index],
        textLine3: textLine3List[index],
        stars: starsList[index],
        imageUrl: "https://anyvan-map-images.s3.eu-west-1.amazonaws.com/armchair.png"
      }
    })
    commit(_SET_MAP_LOCATIONS, locations)
  }
}
