import { ActionContext } from "vuex"
import { finalApolloClient, gql, disableFragmentWarnings } from "./../graphql-client" // path like this because we are using it in nuxt config

// TODO - temp solution check later which fragment is problematic
disableFragmentWarnings()

const pagesQuery = gql`
  fragment pageInfoData on WPPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
  query GetPages(
    $where: RootQueryToPageConnectionWhereArgs
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    pages(where: $where, after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        ...pageInfoData
      }
      edges {
        node {
          id
          title
          date
          slug
          status
          uri
          featuredImage {
            node {
              id
              sourceUrl
            }
          }
        }
      }
    }
  }
`

const pagesQueryForGeneration = gql`
  fragment pageInfoData on WPPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
  fragment listAttributesData on Attribute {
    name
    value
  }
  fragment listBlockData on Block {
    type
    tagName
    attributes {
      ...listAttributesData
    }
    innerHtml
  }
  fragment innerBlocksRecursive on Block {
    innerBlocks {
      ...listBlockData
      innerBlocks {
        ...listBlockData
        innerBlocks {
          ...listBlockData
          innerBlocks {
            ...listBlockData
            innerBlocks {
              ...listBlockData
              innerBlocks {
                ...listBlockData
                innerBlocks {
                  ...listBlockData
                  innerBlocks {
                    ...listBlockData
                    innerBlocks {
                      ...listBlockData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment listCategoriesData on Category {
    categoryId
    name
  }
  fragment listTagsData on Tag {
    slug
  }
  query GetPages(
    $where: RootQueryToPageConnectionWhereArgs
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    pages(where: $where, after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        ...pageInfoData
      }
      edges {
        node {
          id
          slug
          title
          language {
            code
          }
          content
          blocks {
            ...listBlockData
            ...innerBlocksRecursive
          }
          frontendUrl
          location {
            mapPinsStars
            mapPinsLatitude
            mapPinsLongitude
            mapPinsTextLine1
            mapPinsTextLine2
            mapPinsTextLine3
            mapPinsImageIDs
          }
          uri
          status
          seo {
            metaRobotsNoindex
            metaRobotsNofollow
            canonical
            metaDesc
            opengraphTitle
            opengraphType
            opengraphUrl
            opengraphDescription
            opengraphSiteName
            twitterTitle
            twitterDescription
            twitterImage {
              mediaDetails {
                sizes {
                  sourceUrl
                  name
                }
              }
            }
            opengraphImage {
              mediaDetails {
                sizes {
                  sourceUrl
                  name
                }
              }
            }
            breadcrumbs {
              text
              url
            }
          }
          featuredImage {
            node {
              id
              sourceUrl
            }
          }
          tags {
            nodes {
              ...listTagsData
            }
          }
          pageFolders {
            edges {
              node {
                slug
                parentId
                name
                id
              }
            }
          }
          template {
            templateName
          }
          author {
            node {
              name
            }
          }
          date
          pageNavigation {
            featuredImageLink
            link
            rel
            title
          }
          categories {
            nodes {
              ...listCategoriesData
            }
          }
          customPhoneNumber
          internalReviews {
            placeId
            zoomLevel
            categoryIds
            minStars
            limit
          }
          locationForm {
            categoryId
          }
        }
      }
    }
  }
`

const pageQuery = gql`
  fragment listAttributesData on Attribute {
    name
    value
  }
  fragment listBlockData on Block {
    type
    tagName
    attributes {
      ...listAttributesData
    }
    innerHtml
  }
  fragment innerBlocksRecursive on Block {
    innerBlocks {
      ...listBlockData
      innerBlocks {
        ...listBlockData
        innerBlocks {
          ...listBlockData
          innerBlocks {
            ...listBlockData
            innerBlocks {
              ...listBlockData
              innerBlocks {
                ...listBlockData
                innerBlocks {
                  ...listBlockData
                  innerBlocks {
                    ...listBlockData
                    innerBlocks {
                      ...listBlockData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment listCategoriesData on Category {
    categoryId
    slug
  }
  fragment pageData on Page {
    id
    slug
    title
    language {
      code
    }
    content
    blocks {
      ...listBlockData
      ...innerBlocksRecursive
    }
    frontendUrl
    location {
      mapPinsStars
      mapPinsLatitude
      mapPinsLongitude
      mapPinsTextLine1
      mapPinsTextLine2
      mapPinsTextLine3
      mapPinsImageIDs
    }
    uri
    status
    seo {
      metaRobotsNoindex
      metaRobotsNofollow
      canonical
      metaDesc
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphDescription
      opengraphSiteName
      twitterTitle
      twitterDescription
      twitterImage {
        mediaDetails {
          sizes {
            sourceUrl
            name
          }
        }
      }
      opengraphImage {
        mediaDetails {
          sizes {
            sourceUrl
            name
          }
        }
      }
      breadcrumbs {
        text
        url
      }
    }
    featuredImage {
      node {
        id
        sourceUrl
      }
    }
    pageFolders {
      edges {
        node {
          slug
          parentId
          name
          id
        }
      }
    }
    template {
      templateName
    }
    author {
      node {
        name
      }
    }
    date
    pageNavigation {
      featuredImageLink
      link
      rel
      title
    }
    categories {
      nodes {
        ...listCategoriesData
      }
    }
    customPhoneNumber
    internalReviews {
      placeId
      zoomLevel
      categoryIds
      minStars
      limit
    }
    locationForm {
      categoryId
    }
  }
  query GetContentNode($id: ID!) {
    contentNode(id: $id, idType: URI) {
      ... on Page {
        ...pageData
      }
    }
  }
`

const pageQueryById = gql`
  fragment listAttributesData on Attribute {
    name
    value
  }
  fragment listBlockData on Block {
    type
    tagName
    attributes {
      ...listAttributesData
    }
    innerHtml
  }
  fragment innerBlocksRecursive on Block {
    innerBlocks {
      ...listBlockData
      innerBlocks {
        ...listBlockData
        innerBlocks {
          ...listBlockData
          innerBlocks {
            ...listBlockData
            innerBlocks {
              ...listBlockData
              innerBlocks {
                ...listBlockData
                innerBlocks {
                  ...listBlockData
                  innerBlocks {
                    ...listBlockData
                    innerBlocks {
                      ...listBlockData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment listCategoriesData on Category {
    categoryId
    slug
  }
  fragment reviewPageData on Page {
    id
    slug
    title
    language {
      code
    }
    content
    blocks {
      ...listBlockData
      ...innerBlocksRecursive
    }
    frontendUrl
    location {
      mapPinsStars
      mapPinsLatitude
      mapPinsLongitude
      mapPinsTextLine1
      mapPinsTextLine2
      mapPinsTextLine3
      mapPinsImageIDs
    }
    uri
    status
    seo {
      metaRobotsNoindex
      metaRobotsNofollow
      canonical
      metaDesc
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphDescription
      opengraphSiteName
      twitterTitle
      twitterDescription
      twitterImage {
        mediaDetails {
          sizes {
            sourceUrl
            name
          }
        }
      }
      opengraphImage {
        mediaDetails {
          sizes {
            sourceUrl
            name
          }
        }
      }
      breadcrumbs {
        text
        url
      }
    }
    featuredImage {
      node {
        id
        sourceUrl
      }
    }
    pageFolders {
      edges {
        node {
          slug
          parentId
          name
          id
        }
      }
    }
    template {
      templateName
    }
    author {
      node {
        name
      }
    }
    date
    pageNavigation {
      featuredImageLink
      link
      rel
      title
    }
    categories {
      nodes {
        ...listCategoriesData
      }
    }
    customPhoneNumber
    internalReviews {
      placeId
      zoomLevel
      categoryIds
      minStars
      limit
    }
    locationForm {
      categoryId
    }
  }
  query GetContentNode($id: ID!) {
    contentNode(id: $id, idType: DATABASE_ID) {
      ... on Page {
        ...reviewPageData
      }
    }
  }
`

const mediaQuery = gql`
  fragment mediaData on MediaItem {
    id
    sizes
    srcSet
    sourceUrl
    altText
    mediaDetails {
      sizes {
        height
        width
        sourceUrl
        name
      }
      width
      height
    }
  }
  query GetMedia($id: ID!, $idType: MediaItemIdType) {
    mediaItem(id: $id, idType: $idType) {
      ... on MediaItem {
        ...mediaData
      }
    }
  }
`

const categoryIdQuery = gql`
  query GetCategory($slug: [String]) {
    categories(where: { slug: $slug }) {
      edges {
        node {
          categoryId
        }
      }
    }
  }
`

const getGraphQLOptions = (config: CMSGraphQLRequestOptions): CMSApolloOptions => {
  const options: any = {
    uri: config.graphqlEndpoint
  }

  if (config.hostHeader) {
    options.headers = {
      Host: config.hostHeader
    }
  }

  return options
}

export function getPages(config, variables?: any): any | null {
  return finalApolloClient(getGraphQLOptions(config)).query({
    query: pagesQuery,
    variables,
    fetchPolicy: "no-cache"
  })
}

export function getPagesForGeneration(config, variables: any): any | null {
  return finalApolloClient(getGraphQLOptions(config)).query({
    query: pagesQueryForGeneration,
    variables,
    fetchPolicy: "no-cache"
  })
}

export function getPage(config, slug: string): any | null {
  return finalApolloClient(getGraphQLOptions(config)).query({
    query: pageQuery,
    variables: {
      id: slug
    },
    fetchPolicy: "no-cache"
  })
}

export function getMediaById(config, id, idType): any | null {
  return finalApolloClient(getGraphQLOptions(config)).query({
    query: mediaQuery,
    variables: {
      id,
      idType
    },
    fetchPolicy: "no-cache"
  })
}

export function getPageById(config, id): any | null {
  return finalApolloClient(getGraphQLOptions(config)).query({
    query: pageQueryById,
    variables: {
      id
    },
    fetchPolicy: "no-cache"
  })
}

export function getCategoryIdBySlug(config: CMSGraphQLRequestOptions, slug): any | null {
  return finalApolloClient(getGraphQLOptions(config)).query({
    query: categoryIdQuery,
    variables: {
      slug
    },
    fetchPolicy: "no-cache"
  })
}
