import { differenceInSeconds, format } from "date-fns"

/**
 * Function to convert date into time ago
 *
 * @param date string
 * @return string
 */
export function timeAgo(date: string): string {
  if(!date) {
    return "No date provided"
  }

  const periods = ["second", "minute", "hour", "day", "week", "month", "year", "decade"]
  const lengths = ["60","60","24","7","4.35","12","10"]
  const dateObj = new Date(date)
  const nowObj = new Date()

  let difference = 0
  let tense = "ago"
  // is it future date or past date
  if(nowObj > dateObj) {
    difference = differenceInSeconds(nowObj, dateObj)
  } else {
    difference = differenceInSeconds(dateObj, nowObj)
    tense = "from now"
  }

  let j = 0
  for(j = 0; difference >= parseInt(lengths[j]) && j < lengths.length-1; j++) {
    difference /= parseInt(lengths[j])
  }

  difference = Math.round(difference)

  if(difference != 1) {
    periods[j] += "s"
  }

  return `${difference} ${periods[j]} ${tense}`
}

/**
 * Function to convert date to a timestamp
 *
 * @param date Date | string
 * @return string
 */
export function formatDateForTimestamp(date?: Date | string): string {
  if(typeof date === 'string' || date instanceof String) {
    date = new Date(date)
  }
  return format(date ?? new Date(), 'yyyy-MM-dd HH:mm:ss')
}

/**
 * Function to get innerText from an HTML string
 * @param html string
 * @return string
 */
export function getInnerText(html: string): string {
  const textContentRegex = /<[^>]*>([^<]*)<\/[^>]*>/g
  const matches = html.match(textContentRegex)

  if (!matches) {
    return ""
  }

  return matches
    .map(match => match.replace(textContentRegex, "$1"))
    .join(" ")
    .trim()
}

export function getScreenWidth() {
  if (typeof window !== "undefined") {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  }
  return null
}
